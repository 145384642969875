// Images file for 'qualifiedsolarsurvey'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Arrow from './assets/nextArrow.png';
import Checked from './assets/checked.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import Favicon from './assets/favicon.ico';
import Uncertain from './assets/Uncertain.png';
import Unchecked from './assets/unchecked.png';

export default {
  Arrow,
  Checked,
  Dropdown,
  Favicon,
  Uncertain,
  Unchecked,
};
